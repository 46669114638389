<template>
  <div>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="400"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <v-row v-if="isMobileWidth === 1" class="ma-4 mt-0 pt-0">
        <v-col cols="12">
          <all-package></all-package>
        </v-col>
      </v-row>
      <v-row v-else class="">
        <v-col cols="12">
          <all-package></all-package>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AllPackage from './components/AllPackage.vue'
import axios from 'axios'

export default {
  name: 'package',
  metaInfo: {
    title: 'List Packages'
  },
  components: {
    AllPackage
  },
  setup() {
    return {
      isLoading:false,
      lastCampaign:[],
      topContent:[],
      totalInfluencers:[],
      totalImpressions:[],
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
