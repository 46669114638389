<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1" class="mb-10 pb-5">
      <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          class="round mb-5"
          elevation="0"
          outlined
          width="100%"
          v-for="n in 3"
            :key="n"
            :ref="'Load'+n"
        >
          <v-card-text class="pa-2 pb-0">
            <v-skeleton-loader
              type="card"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          class="round mb-5"
          elevation="0"
          outlined
          v-for="list in listPackages" :key="list.id"
        >
          <v-img @click="openDetail(list.id)" height="200" :src="list.photo"></v-img>
          <v-card-text class="pa-2 pb-0">
            <!-- <p class="left-text black--text text-14 font-weight-bold mb-1">Paket Micro Influencer akdjaw ada</p>
            <p class="mb-0 text-12 primary--text font-weight-bold"><i class="fas fa-tag mr-2 pr-1"></i> IDR 2.000.000</p> -->
            <p v-if="list.name.length < 37" class="black--text font-weight-bold mb-1">{{ list.name }}</p>
            <p v-else class="black--text font-weight-bold mb-1">{{list.name.substring(0,37)+".."}}</p>
            <p class="font-weight-semibold mb-0"><i class="fas fa-tag mr-1"></i> IDR {{ list.price | numberFormat }}</p>
            <div class="row">
              <v-col cols="6">
                <v-btn @click="buy(list.name)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Buy Package</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="openDetail(list.id)" elevation="0" block outlined rounded class="text-capitalize mb-2 mt-2" small color="primary">Lihat Detail</v-btn>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else class="mb-10 pb-5">
      <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          class="round mb-5"
          elevation="0"
          outlined
          width="270px"
          v-for="n in 4"
            :key="n"
            :ref="'Load'+n"
        >
          <v-card-text class="pa-2 pb-0">
            <v-skeleton-loader
              type="card"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="row d-flex justify-space-between pt-3">
        <v-col class="pa-0" cols="3" v-for="list in listPackages" :key="list.id">
          <v-card
            height=""
            class="round ma-3"
            elevation="0"
            outlined
          >
            <v-img @click="openDetail(list.id)" class="pointer" height="200" :src="list.photo"></v-img>
            <v-card-text class="pa-2 pb-0">
              <p @click="openDetail(list.id)" v-if="list.name.length < 30" class="left-text black--text font-weight-bold mb-1 pointer">{{ list.name }}</p>
              <p @click="openDetail(list.id)" v-else class="left-text black--text font-weight-bold mb-1 pointer">{{list.name.substring(0,30)+".."}}</p>
              <p class="font-weight-semibold"><i class="fas fa-tag mr-1"></i> IDR {{ list.price | numberFormat }}</p>
              <v-btn @click="buy(list.name)" elevation="0" block rounded class="text-capitalize mb-3" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Buy Package</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isMobileWidth:0,
      listPackages:[]
    }
  },
  mounted(){
    this.page = this.$route.query.item
    if(this.isMobileWidth === 0){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
          this.reload = false
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
      window.scrollTo(0,0);
    }

    this.loadPackages()
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadPackages(){
      this.isLoading = true;
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'packages/getAllPackagesHome'
        ).then(response => {
          if(response){
            let data = response.data;
            for(let a=0; a < data.length; a++){
              axios({
                // url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                url: process.env.VUE_APP_API_ENDPOINT + 'packages/getImage/'+data[a].photo,
                // headers: {
                //   'Authorization': 'Bearer '+ token
                // },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listPackages = data
            setTimeout(() => {
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    buy(title){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Saya tertarik untuk order paket *'+title+'*. Mohon dibantu proses ya. \n Terima kasih', '_blank');
    },
    openDetail(id){
      this.$router.push({name:'package-detail', query: {item: this.encryptData(id)}});
    },
    openAll(){
      this.$router.push({name:'micro-influencer'});
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
